import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import './navbar.css'

const NavList = styled.ul`
    @media ( max-width: 900px) {
        padding:0 10px;
    }
`
const NavListItem = styled.li`
    display: inline-block; 
    margin: 10px 10px;

    @media ( max-width: 900px){
        display: initial;
        width: 100%;
    }
`
const LinkNav = styled(Link)`
    font-size: 0.85rem;
    text-decoration: none;
    text-align: left;
    color: black;
    margin-bottom: 15px;
    font-weight: 700;
    line-height: 0.5rem;

    @media ( max-width:900px ) {
        color: bisque;
        display: block;
        font-size: 1rem;
        line-height: 1rem
    }

`

const  LinkNavSpan = styled.span`
    display: block;
    font-size: 0.75rem;
    font-weight: 400; 
`

const Button = styled.button`
    float: right;
    align-self: center;
    background: transparent;
    border: solid 2px;
    border-radius: 8px;
    margin-top: 15px;
    
    @media ( min-width: 901px ) {
        display: none;
    }
`

class Navbar extends React.Component {
    state = { condition: false }
    toogleClass = () => {
        this.setState({
            condition: !this.state.condition
        })
    }
   
    render(){
        return (
            <div>
                <Button onClick={this.toogleClass} >Menu</Button>
                <div className= { this.state.condition ? "menu toggled" : "menu"} > 
                    <NavList>
                    <NavListItem>
                            <LinkNav to="/page-2/" activeClassName="active">
                                <LinkNavSpan>Acerca de el</LinkNavSpan> Metodo McKenzie?
                            </LinkNav>
                        </NavListItem>
                        <NavListItem>
                            <LinkNav to="/que-es/" activeClassName="active">
                                <LinkNavSpan>Que es el</LinkNavSpan> Metodo McKenzie?
                            </LinkNav>
                        </NavListItem>
                        <NavListItem>
                            <LinkNav to="/como-funciona/" activeClassName="active">
                                <LinkNavSpan>Como Funciona el</LinkNavSpan> Metodo McKenzie
                            </LinkNav>
                        </NavListItem>
                        <NavListItem>
                            <LinkNav to="/es-apropiado/" activeClassName="active">
                                <LinkNavSpan>Es Apropiado</LinkNavSpan> Para Mi
                            </LinkNav>
                        </NavListItem>
                        <NavListItem>
                            <LinkNav to="/agendar-cita/" activeClassName="active">
                                <LinkNavSpan>Agendar</LinkNavSpan> Una Cita
                            </LinkNav>
                        </NavListItem>
                    </NavList>
            </div>
        </div>
        )
    }
}

export default Navbar