import React from 'react'
import { Link } from 'gatsby'
import Image from '../components/images/image-one'
import ImageTwo from '../components/images/image-two'
import donut from '../images/donut-chart.svg'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

import lineaTriangulo from '../images/linea-triangulo.png'
import lineaCuadrado from '../images/linea-cuadrado.png'

const InnerGrid = styled.div`
    display: grid; 
    grid-template-columns: 1fr 320px;
    grid-gap: 20px;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
`
const Phrase = styled.blockquote`
    font-weight: 700; 
    font-size: 24px;
    padding-left: 20px; 
    border-left: 4px solid black;
`
const ImageFrame = styled.ul`
  li {
    padding-bottom: 10px;
    margin-bottom: 1rem;
    background-image: url(${lineaCuadrado});
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
  }

  li:last-child {
    background-image: url(${lineaTriangulo});
  }
`

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <InnerGrid>
    <div>
    <p>El sistema de tratamiento conocido como  Método McKenzie® de Diagnóstico y Terapia Mecánica® (MDT) es un método seguido y usado por fisioterapeutas y pacientes a nivel internacional con patologías de la zona lumbar, cervical y articulaciones de las extremidades.
    </p>
    <p>La mayoría de los dolores músculo-esqueléticos tienen un origen “mecánico” , es decir, que en las actividades cotidianas los movimientos y posturas pueden provocar dolor. Robin McKenzie, un fisioterapeuta de Nueva Zelanda, descubrió a través de una evaluación sistemática de sus pacientes que ciertos movimientos y posturas pueden eliminar el dolor y restaurar la función. </p>
    <Phrase>Reconocido internacionalmente como tratamiento líder para problemas de columna lumbar, de cuello y extremidades</Phrase>
    <p>Robin McKenzie creía que la mayoría de los pacientes podían aprender a tratarse a sí mismos si se les enseñaba correctamente. Pensaba que el autotratamiento reforzaba al paciente y reducía su dependencia a las intervenciones médicas.  El Método McKenzie o MDT favorece el potencial del organismo para curar por sí mismo sin medicación, cirugía u otras modalidades pasivas de tratamiento como pueden ser calor, hielo o terapia de ultrasonidos. Está dirigido a los propios pacientes y a las entidades que financian pública o privadamente los servicios profesionales de fisioterapia para que los pacientes desarrollen las habilidades para el autotratamiento y resuelvan sus dolencias de una manera menos costosa y más efectiva.
</p>
  <img src={donut} alt={`donut-chart  `} />
 </div>
 <div>
  <ImageFrame>
    <li><Image /></li>
    <li><ImageTwo /></li>
  </ImageFrame>
 </div>
 <Link to="/que-es" className={`mdt-button-simple`}>Que es el Metodo McKenzie&copy;</Link>
 </InnerGrid>
  </Layout>
)

export default SecondPage

