import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Navbar from './navbar'
import logo from '../images/mdt.svg'

const NavMenu = styled.div`
    display: grid;
    grid-template-columns: 45% 1fr;
    margin: 0;
    padding-top: 10px;

    @media ( max-width: 1024px) {
      grid-template-columns: 30% 1fr;
      grid-gap: 20px;
    }

    @media ( max-width: 900px ) {
      grid-template-columns: 1fr 80px;
      padding-top: 0; 
    }
`
const NavLogo = styled.img`
   width: 350px ;

  @media (max-width: 900px) {
    margin-bottom: 0;
  }
`

const Header = ({ siteTitle }) => (
  <div
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <NavMenu>
      <div>
        <Link
          to="/"
          >
          <NavLogo src={logo} alt={siteTitle} />
        </Link>
      </div>
      <Navbar />
      </NavMenu>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

