import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImageTwo = () => (
  <StaticQuery
    query={graphql`
      query {
        imageTwo: file(relativePath: { eq: "mdt-10.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.imageTwo.childImageSharp.fluid} alt={`mdt-9`}/>}
  />
)
export default ImageTwo
