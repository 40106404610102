import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'

import Header from './header'
import background from '../images/background.jpg'
import './layout.css'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`


const Container = styled.div`
  background-image: url(${background});
  min-height: 100vh;
`
const Grid = styled.div`
    display: grid;
    grid-template-areas: 
    "header"
    "rowcontent"
    "footer";
    grid-template-columns: 1fr;
    grid-template-rows: 200px 1fr 50px;
    max-width: 1240px;
    margin: 0 auto;

    @media ( max-width: 900px) {
      grid-template-rows: 100px 1fr 150px;
    }
`

const GridHeader = styled(Header)`
  grid-area: header;
`
const GridContent = styled.div`
   animation: 1s ${fadeIn} ease-out;
   grid-area: rowcontent; 
   margin: 0 auto;
   padding: 0px 1.0875rem 1.45rem;
   padding-top: 0;
`

const Footer = styled.footer`
  grid-area: footer;
  margin: 0 auto;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;

  @media (max-width:900px){
    text-align: center;
  }
`

const Layout = ({ children, ...props }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Container >
      <Grid>
      <GridHeader siteTitle={data.site.siteMetadata.title} />
          <GridContent>
            {children}
          </GridContent>
        <Footer>
            © {new Date().getFullYear()}, Hecho con {` `} <a href="https://www.gatsbyjs.org">Gatsby</a> | Desarrollado por { ` `} <a href="https://elpuas.com">el.puas()</a>
          </Footer>
          </Grid>
      </Container>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
